import { Script, graphql } from "gatsby"
import React from "react"
import ContactSales from "../components/common/ContactSales"
import MainLayout from "../layouts/MainLayout"
import Banner from "../components/no-code/Banner"
import ItStaff from "../components/web-application/ItStaff"
import Shape from "../components/web-application/Shape"
import Sets from "../components/consulting/Sets"
import PackedIndustry from "../components/web-application/PackedIndustry"
import Projects from "../components/web-application/Projects"
import Opportunity from "../components/web-application/Opportunity"
import PowerUp from "../components/no-code/PowerUp"
import PowerUpMobile from "../components/no-code/PowerUpMobile"
import Maximizing from "../components/no-code/Maximizing"
import Systematic from "../components/no-code/Systematic"
import SystematicMobile from "../components/no-code/SystematicMobile"
import ClientsWeb from "../components/web-application/ClientsWeb"
import ProjectsMobile from "../components/web-application/ProjectsMobile"
import ConsultingFaqs from "../components/consulting/ConsultingFaqs"
export const Head = () => {
  return (
    <>
      <Script
        src="https://unpkg.com/@lottiefiles/lottie-player@latest/dist/lottie-player.js"
        strategy="post-hydrate"
      />

      <title>No Code Development Services - InvoZone</title>
      <meta
        name="description"
        content="Revolutionize your software journey with InvoZone's No Code Development Services. Swift, tailored solutions for diverse needs. Schedule a consultation now!"
      />
      <meta name="robots" content="noindex, nofollow"></meta>
      <meta name="googlebot" content="noindex, nofollow"></meta>
    </>
  )
}
const Nocode = ({ data }) => {
  // console.log(data)
  const [isMobile, setIsMobile] = React.useState(false)
  const [isTablet, setIsTablet] = React.useState(false)

  const handleResize = () => {
    if (window.innerWidth <= 767) {
      setIsMobile(true)
      setIsTablet(false)
    } else if (window.innerWidth <= 1280) {
      setIsTablet(true)
      setIsMobile(false)
    } else {
      setIsMobile(false)
      setIsTablet(false)
    }
  }

  React.useEffect(() => {
    setIsMobile(window.innerWidth <= 767)
    setIsTablet(window.innerWidth <= 1280)
    window.addEventListener("resize", handleResize)
    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [])

  const heroSection = data?.strapiPage?.sections[0]
  const applications = data?.strapiPage?.sections[1]
  const success = data?.strapiPage?.sections[2]
  const discover = data?.strapiPage?.sections[3]
  const need = data?.strapiPage?.sections[4]
  const powerup = data?.strapiPage?.sections[5]
  const maximizing = data?.strapiPage?.sections[6]
  const system = data?.strapiPage?.sections[7]
  const lead = data?.strapiPage?.sections[8]
  const noCodeIndustry = data?.strapiPage?.sections[9]
  const noClients = data?.strapiPage?.sections[10]
  const projects = data?.strapiPage?.sections[11]
  const faqs = data?.strapiPage?.sections[12]
  //   const testimonials = data?.testimonials?.sections[0]

  return (
    <MainLayout>
      <Banner strapiData={heroSection} breadCrumb={data?.strapiPage?.buttons} />
      <ItStaff strapiData={applications} noCode={true} />
      <Shape strapiData={success} noCodeWeb={true} />
      <Sets strapiData={discover} nocodeImg={true} />
      <Opportunity strapiData={need} />
      {!isMobile && !isTablet ? (
        <PowerUp strapiData={powerup} />
      ) : (
        <PowerUpMobile strapiData={powerup} />
      )}
      <Maximizing strapiData={maximizing} />
      {!isMobile && !isTablet ? (
        <Systematic strapiData={system} />
      ) : (
        <SystematicMobile strapiData={system} />
      )}
      <Opportunity strapiData={lead} cmsopportunity={true} />
      <PackedIndustry strapiData={noCodeIndustry} noCode={noCodeIndustry} />
      <ClientsWeb strapiData={noClients} nocodeclient={true} />
      {!isMobile && !isTablet ? (
        <Projects strapiData={projects} />
      ) : (
        <ProjectsMobile strapiData={projects} />
      )}
      <ConsultingFaqs strapiData={faqs} />
      <ContactSales bgColor="#f8f8f8" />
    </MainLayout>
  )
}

export const query = graphql`
  query ElixirPage {
    strapiPage(slug: { eq: "no-code" }) {
      sections {
        title
        subTitle
        subTitle2
        cards {
          title
          subTitle
          description: childStrapiComponentCardsCardDescriptionTextnode {
            description
          }
          image1 {
            alternativeText
            localFile {
              publicURL
            }
            url
          }
          image2 {
            alternativeText
            localFile {
              publicURL
            }
          }
          buttons {
            title
            url
          }
        }
        secImages {
          alternativeText
          localFile {
            childImageSharp {
              gatsbyImageData
            }
            publicURL
          }
          url
        }
        buttons {
          title
          url
        }
        description: childStrapiComponentSectionsSectionDescriptionTextnode {
          id
          description
        }
      }
      buttons {
        url
        title
      }
      seo {
        metaTitle
        metaDescription
      }
    }
    testimonials: strapiPage(slug: { eq: "testimonial" }) {
      sections {
        title
        subTitle
        cards {
          title
          subTitle
          image1 {
            alternativeText
            localFile {
              publicURL
            }
          }
        }
      }
    }
  }
`

export default Nocode
