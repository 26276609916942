// extracted by mini-css-extract-plugin
export var btn = "Maximizing-module--btn--a8ff9";
export var cards = "Maximizing-module--cards--38935";
export var description = "Maximizing-module--description--b561d";
export var description2 = "Maximizing-module--description2--a7252";
export var hire = "Maximizing-module--hire--19182";
export var hireImg = "Maximizing-module--hireImg--c12d6";
export var maximizingNoCode = "Maximizing-module--maximizingNoCode--ff949";
export var maximizingTitle = "Maximizing-module--maximizingTitle--9e7ab";
export var subTitle = "Maximizing-module--subTitle--beb20";
export var talent = "Maximizing-module--talent--15f1d";
export var teamBtn = "Maximizing-module--teamBtn--89dd9";
export var text = "Maximizing-module--text--2d072";
export var tick = "Maximizing-module--tick--af1f0";