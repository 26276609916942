import React from "react"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Row from "react-bootstrap/Row"
import tick from "../../images/web-application/arrowweb.svg"
import * as styles from "./Maximizing.module.scss"

const Maximizing = ({ strapiData }) => {
  const image = getImage(strapiData?.secImages[0]?.localFile)

  return (
    <div className={styles.maximizingNoCode}>
      <Container>
        <Row className="gap-30 align-items-center">
          <Col
            lg={{ span: 6, order: 1 }}
            md={{ span: 12, order: 1 }}
            xs={{ span: 12, order: 1 }}
          >
            <div className={styles.hire}>
              <h2 dangerouslySetInnerHTML={{ __html: strapiData?.title }} />
              <div
                className={styles.description}
                dangerouslySetInnerHTML={{
                  __html: strapiData?.subTitle,
                }}
              />
              <div
                className={styles.description2}
                dangerouslySetInnerHTML={{
                  __html: strapiData?.subTitle2,
                }}
              />
            </div>

            <div className={styles.cards}>
              {strapiData?.cards &&
                strapiData?.cards?.map((item, index) => (
                  <div className={styles.talent} key={index}>
                    <img className={styles.tick} src={tick} alt={item?.title} />
                    <div className={styles.text}>
                      <h3 className={styles.maximizingTitle}>{item?.title}</h3>
                      <p
                        dangerouslySetInnerHTML={{
                          __html: item?.subTitle,
                        }}
                      />
                    </div>
                  </div>
                ))}
            </div>
          </Col>
          <Col
            lg={{ span: 6, order: 2 }}
            md={{ span: 12, order: 2 }}
            xs={{ span: 12, order: 2 }}
          >
            <div className={styles.hire}>
              {image ? (
                <GatsbyImage
                  placeholder="blurred"
                  decoding="async"
                  loading="lazy"
                  image={image}
                  alt={strapiData?.title}
                />
              ) : (
                <img
                  src={strapiData?.secImages[0]?.localFile?.publicURL}
                  alt={strapiData?.secImages[0]?.alternativeText}
                  decoding="async"
                  loading="lazy"
                />
              )}
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default Maximizing
