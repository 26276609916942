// extracted by mini-css-extract-plugin
export var cardBg = "PowerUp-module--cardBg--32f81";
export var cards = "PowerUp-module--cards--b5698";
export var cardsContent = "PowerUp-module--cardsContent--7a3f6";
export var description = "PowerUp-module--description--72281";
export var heading = "PowerUp-module--heading--3a8ee";
export var iconssCard = "PowerUp-module--iconssCard--a2a49";
export var nav = "PowerUp-module--nav--72f86";
export var navItem = "PowerUp-module--nav-item--2c886";
export var navbarBlock = "PowerUp-module--navbarBlock--7618f";
export var powerUpnoCode = "PowerUp-module--powerUpnoCode--df3ca";
export var tabData = "PowerUp-module--tabData--efba7";
export var tech = "PowerUp-module--tech--7652a";
export var techIcon = "PowerUp-module--techIcon--73c78";
export var techImg = "PowerUp-module--techImg--ac7b4";
export var technologyIcon = "PowerUp-module--technologyIcon--29214";