// extracted by mini-css-extract-plugin
export var SliderWidth = "PackedIndustry-module--SliderWidth--2827b";
export var arrowImg = "PackedIndustry-module--arrowImg--abfd7";
export var card = "PackedIndustry-module--card--0cd2d";
export var cardWrapper = "PackedIndustry-module--cardWrapper--d6e14";
export var cards = "PackedIndustry-module--cards--42295";
export var cmsBottomimg = "PackedIndustry-module--cmsBottomimg--7f54d";
export var cmsCard = "PackedIndustry-module--cmsCard--e8547";
export var cmsIndustriesWeb = "PackedIndustry-module--cmsIndustriesWeb--3c17b";
export var description = "PackedIndustry-module--description--04572";
export var heading = "PackedIndustry-module--heading--f06e1";
export var headingPremium = "PackedIndustry-module--headingPremium--33ca4";
export var iconContainer = "PackedIndustry-module--iconContainer--5a2ac";
export var iconContainerLeft = "PackedIndustry-module--iconContainerLeft--55c5b";
export var noCodeCard = "PackedIndustry-module--noCodeCard--c051c";
export var nocodeIndustry = "PackedIndustry-module--nocodeIndustry--0379b";
export var packedIndustryContainer = "PackedIndustry-module--packedIndustryContainer--e2a29";
export var portfolioArrowIcon = "PackedIndustry-module--portfolioArrowIcon--ab090";
export var portfolioArrowIconCover = "PackedIndustry-module--portfolioArrowIconCover--dd3cd";
export var portfolioArrowRightIconCover = "PackedIndustry-module--portfolioArrowRightIconCover--88a98";
export var providingImg = "PackedIndustry-module--providingImg--2a197";
export var trailBg = "PackedIndustry-module--trailBg--b1c67";