// extracted by mini-css-extract-plugin
export var ConsultingsectionFaqs = "ConsultingFaqs-module--ConsultingsectionFaqs--d5b9b";
export var faqButton = "ConsultingFaqs-module--faqButton--3537e";
export var faqsNum = "ConsultingFaqs-module--faqsNum--a4cae";
export var faqsSub = "ConsultingFaqs-module--faqsSub--5537f";
export var head = "ConsultingFaqs-module--head--20e02";
export var quesColor = "ConsultingFaqs-module--quesColor--f8a5f";
export var quesColorSelected = "ConsultingFaqs-module--quesColorSelected--abc0a";
export var serial = "ConsultingFaqs-module--serial--fb197";
export var serial2 = "ConsultingFaqs-module--serial2--42d2e";
export var textColor = "ConsultingFaqs-module--textColor--98542";