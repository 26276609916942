import { Link } from "gatsby"
import React, { useEffect, useRef, useState } from "react"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Slider from "react-slick"
import borderBottom from "../../images/web-application/bottomimg.svg"
import * as styles from "./PackedIndustry.module.scss"
import "./PackedIndustry.scss"

const Services = ({ strapiData, cmsIndustries, noCode }) => {
  const slide1 = useRef(null)
  const [current, setCurrent] = useState(0)
  const [current_1, setCurrent_1] = useState(0)
  const [current_2, setCurrent_2] = useState(0)

  useEffect(() => {
    ;(current === strapiData?.cards.length - 5 ||
      current === strapiData?.cards.length - 4 ||
      current === strapiData?.cards.length - 3 ||
      current_1 === strapiData?.cards.length - 2 ||
      current_2 === strapiData?.cards.length - 1) &&
      setTimeout(() => {
        slide1?.current?.slickGoTo(0)
      }, 3000)
  }, [current, current_1, current_2])

  const settings = {
    autoplay: true,
    dots: true,
    dotsClass: "mainSliderwebDevelopmentPackedIndustry",
    slidesToShow: 5,
    speed: 2000,
    autoplaySpeed: 2000,
    slidesToScroll: 1,
    infinite: false,
    arrows: false,
    // loop:true,
    beforeChange: (current, next) => setCurrent(next),
    lazyLoad: "ondemand",
    responsive: [
      {
        breakpoint: 1140,
        settings: {
          slidesToShow: 3,
          beforeChange: (current, next) => setCurrent_1(next),
        },
      },
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 3,
          beforeChange: (current, next) => setCurrent_1(next),
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 3,
          beforeChange: (current, next) => setCurrent_2(next),
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          beforeChange: (current, next) => setCurrent_2(next),
        },
      },
    ],
  }

  return (
    <React.Fragment>
      <div
        className={`${styles.packedIndustryContainer} ${
          cmsIndustries ? styles.cmsIndustriesWeb : ""
        } ${noCode ? styles.nocodeIndustry : ""}`}
      >
        <Container>
          <h2
            className={styles.heading}
            dangerouslySetInnerHTML={{ __html: strapiData?.title }}
          />
          <div
            className={styles.description}
            dangerouslySetInnerHTML={{
              __html: strapiData?.subTitle,
            }}
          />
          <Row className={` ${styles.cardWrapper}`}>
            <Slider
              {...settings}
              className={`${styles.SliderWidth} mainSliderwebDevelopmentPacked `}
              ref={slide1}
            >
              {strapiData?.cards &&
                strapiData?.cards?.map((e, i) => (
                  <Col xs={12} key={i}>
                    <Link to={e?.buttons[0]?.url}>
                      <div
                        className={`${styles.card} ${
                          cmsIndustries ? styles.cmsCard : ""
                        } ${noCode ? styles.noCodeCard : ""}`}
                      >
                        <Link to={e?.buttons[0]?.url}>
                          <h3>{e?.title}</h3>
                        </Link>
                        <lottie-player
                          autoplay
                          loop
                          src={e?.image1[0]?.localFile?.publicURL}
                          style={{
                            height: "110px",
                            margin: "35px 0 10px 0",
                          }}
                        />
                        <img
                          className={cmsIndustries ? styles.cmsBottomimg : ""}
                          src={borderBottom}
                          alt=""
                          decoding="async"
                          loading="lazy"
                        />
                      </div>
                    </Link>
                  </Col>
                ))}
            </Slider>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Services
