// extracted by mini-css-extract-plugin
export var cardBg = "PowerUpMobile-module--cardBg--274c3";
export var cards = "PowerUpMobile-module--cards--e69a2";
export var cardsContent = "PowerUpMobile-module--cardsContent--20d4b";
export var description = "PowerUpMobile-module--description--c3b0d";
export var heading = "PowerUpMobile-module--heading--ca7ca";
export var iconssCard = "PowerUpMobile-module--iconssCard--2802a";
export var nav = "PowerUpMobile-module--nav--01ffb";
export var navItem = "PowerUpMobile-module--nav-item--9629c";
export var navbarBlock = "PowerUpMobile-module--navbarBlock--fde1c";
export var powerUpTechMobile = "PowerUpMobile-module--powerUpTechMobile--53021";
export var tabData = "PowerUpMobile-module--tabData--a9a09";
export var tech = "PowerUpMobile-module--tech--03a07";
export var techIcon = "PowerUpMobile-module--techIcon--4a004";
export var techImg = "PowerUpMobile-module--techImg--d0539";
export var technologyIcon = "PowerUpMobile-module--technologyIcon--ec149";