// extracted by mini-css-extract-plugin
export var cards = "Sets-module--cards--c6f17";
export var description = "Sets-module--description--e3966";
export var hireImg = "Sets-module--hireImg--5f9ef";
export var hireTechnicalSetsConsulting = "Sets-module--hireTechnicalSetsConsulting--6536d";
export var nocodeWeb = "Sets-module--nocodeWeb--9bb37";
export var subTitle = "Sets-module--subTitle--5f7f7";
export var talent = "Sets-module--talent--188ff";
export var text = "Sets-module--text--4645a";
export var tick = "Sets-module--tick--96050";
export var whyChooseConsultingWeb = "Sets-module--whyChooseConsultingWeb--e43e0";