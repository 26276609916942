import React, { useState } from "react"
import Accordion from "react-bootstrap/Accordion"
import Container from "react-bootstrap/Container"
import Card from "react-bootstrap/Card"
import "./PowerUpMobile.scss"
import * as styles from "./PowerUpMobile.module.scss"

const PowerUpMobile = ({ strapiData }) => {
  const data = [
    {
      title: "Visual Development Platforms",
      tech: [
        {
          name: "Bubble",
          icon: "https://invozone-backend.s3.amazonaws.com/bubble_4f79f422f2.svg",
        },
        {
          name: "WebFlow",
          icon: "https://invozone-backend.s3.amazonaws.com/webflow_eca83c47ad.svg",
        },
        {
          name: "Adalo",
          icon: "https://invozone-backend.s3.amazonaws.com/adalo_d3188cad49.svg",
        },
        {
          name: "Draftbit",
          icon: "https://invozone-backend.s3.amazonaws.com/draftbit_12e294fb31.svg",
        },
      ],
    },
    {
      title: "Backend",
      tech: [
        {
          name: "AWS Lambda",
          icon: "https://invozone-backend.s3.amazonaws.com/aws_lambda_257b5ac84f.svg",
        },
        {
          name: "Azure Functions",
          icon: "https://invozone-backend.s3.amazonaws.com/azure_svgrepo_80aaed44bd.svg",
        },
        {
          name: "Cloud Databases",
          icon: "https://invozone-backend.s3.amazonaws.com/cloud_database_8a01d75f77.svg",
        },
        {
          name: "Firebase",
          icon: "https://invozone-backend.s3.amazonaws.com/firebase_40f195571c.svg",
        },
        {
          name: "Airtable",
          icon: "https://invozone-backend.s3.amazonaws.com/airtable_df61f78f17.svg",
        },
        {
          name: "APIs",
          icon: "https://invozone-backend.s3.amazonaws.com/rest_apis_ec5e76b0a3.svg",
        },
        {
          name: "Zapier",
          icon: "https://invozone-backend.s3.amazonaws.com/zapier_56f3b8b113.svg",
        },
        {
          name: "Sanity",
          icon: "https://invozone-backend.s3.amazonaws.com/sanity_ed847de157.svg",
        },
      ],
    },
    {
      title: "Frontend",
      tech: [
        {
          name: "React Native",
          icon: "https://invozone-backend.s3.amazonaws.com/react_native_198fa06d9b.svg",
        },
        {
          name: "SwiftUI",
          icon: "https://invozone-backend.s3.amazonaws.com/swift_f17a2f9dd0.svg",
        },
        {
          name: "Bootstrap",
          icon: "https://invozone-backend.s3.amazonaws.com/bootstrap_0e89d2dca2.svg",
        },
        {
          name: "Tailwind",
          icon: "https://invozone-backend.s3.amazonaws.com/tailwind_74774585e0.svg",
        },
        {
          name: "Material Design",
          icon: "https://invozone-backend.s3.amazonaws.com/google_material_design_ea5b13aab1.svg",
        },
      ],
    },

    {
      title: "Automation Tools",
      tech: [
        {
          name: "Zapier",
          icon: "https://invozone-backend.s3.amazonaws.com/zapier_56f3b8b113.svg",
        },
        {
          name: "N8n",
          icon: "https://invozone-backend.s3.amazonaws.com/n8n_058335a7c2.svg",
        },
        {
          name: "Automate.io",
          icon: "https://invozone-backend.s3.amazonaws.com/automate_io_2a7a15ba03.svg",
        },
      ],
    },
    {
      title: "AI/ML Tools",
      tech: [
        {
          name: "Google's AutoML",
          icon: "https://invozone-backend.s3.amazonaws.com/automl_5cf82ff034.svg",
        },
        {
          name: "Image Recognition",
          icon: "https://invozone-backend.s3.amazonaws.com/swiftui_logo_636227aed4.svg",
        },
        {
          name: "Sentiment Analysis",
          icon: "https://invozone-backend.s3.amazonaws.com/sentiment_analysis_f80062f23d.svg",
        },
        {
          name: "Predictive Modeling",
          icon: "https://invozone-backend.s3.amazonaws.com/predictive_modeling_9d6e8acbf3.svg",
        },
      ],
    },
    {
      title: "Database",
      tech: [
        {
          name: "MongoDB",
          icon: "https://invozone-backend.s3.amazonaws.com/mongodb_add8c36d3a.svg",
        },
        {
          name: "PostgreSQL",
          icon: "https://invozone-backend.s3.amazonaws.com/postgresql_e695b314e1.svg",
        },
        {
          name: "MySQL",
          icon: "https://invozone-backend.s3.amazonaws.com/mysql_b1008b93a5.svg",
        },
      ],
    },
  ]

  const [activeAccordion, setActiveAccordion] = useState(`${data[0]?.title}`)
  return (
    <div className="techPowerUpMobile">
      <div className={styles.powerUpTechMobile}>
        <Container>
          <h2
            className={styles.heading}
            dangerouslySetInnerHTML={{ __html: strapiData?.title }}
          ></h2>
          <div
            className={styles.description}
            dangerouslySetInnerHTML={{
              __html: strapiData?.subTitle,
            }}
          />
          <Accordion defaultActiveKey={`${data[0]?.title}`}>
            {data &&
              data?.map((e, i) => (
                <Card className="PowerUpMobileCard" key={i}>
                  <Accordion.Toggle
                    as={Card.Header}
                    eventKey={`${e?.title}`}
                    className={`powerUpAccord ${
                      e?.title === activeAccordion ? "active-accordion" : ""
                    } ${
                      (i === 1 || i === 3 || i === 5) &&
                      e?.title !== activeAccordion
                        ? "second-accordion"
                        : (i === 0 || i === 2 || i === 4) &&
                          e?.title !== activeAccordion
                        ? "first-accordion"
                        : ""
                    }`}
                    onClick={() => setActiveAccordion(e?.title)}
                    style={
                      i === 1 && e?.title === activeAccordion
                        ? { marginTop: "24px" }
                        : {}
                    }
                  >
                    {e?.title}
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey={`${e?.title}`}>
                    <Card.Body className={`cardBody`}>
                      <div className={styles.techIcon}>
                        {e?.tech &&
                          e?.tech?.map((el, i) => (
                            <div className={`${styles.iconssCard}`} key={i}>
                              <div className={styles.technologyIcon}>
                                <div className={styles.techImg}>
                                  <img
                                    decoding="async"
                                    loading="lazy"
                                    src={el?.icon}
                                    alt={el?.name}
                                  />
                                </div>
                                <h3>{el?.name}</h3>
                              </div>
                            </div>
                          ))}
                      </div>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
              ))}
          </Accordion>
        </Container>
      </div>
    </div>
  )
}

export default PowerUpMobile
